.updateContainer {
  background-color: #e1e1df;
  padding: 12px 16px;
}
.gridContainer { 
  max-width: 1140px;
  margin: 0 auto;
  /* display: grid;
  grid-gap: 0 5px;
  grid-template-columns: 40% 50%;
  grid-template-rows: repeat(1, 100%); */
}
.grid {
  max-width: 1140px;
  margin: 0 auto;
  display: grid;
  grid-gap: 0 5px;
  grid-template-columns: 100%;
  grid-template-rows: repeat(1, 100%);
}
.emergencyContent{
  margin-right: 16px;
}
.UpdateContent{
  width: 100%;
}
.updateHeader {
  font-size: 32px;
  font-weight: 700;
}
.whiteBg {
  padding: 12px 16px;
  background-color: #fff;
}
.imageCard>img {
  width: 100%;
  border-radius: 24px 0px 24px 24px;
  
}
.ContactCard>img{
  width: 100%;
  height: 326px;
  border-radius: 24px 0px 24px 24px;
}

.card2 {
  max-width: 1140px;
  margin: 16px auto;
  background-color: #fff;
}

.width_100 {
  width: 100% !important;
}
.selectForm {
  background-color: #ffffff !important;
  border-bottom: none !important;
  border-radius: 8px 0 8px 8px !important;
  width: 100% !important;
  margin: 0 !important;
  padding: 0 !important;
  box-shadow: none !important;
  overflow: hidden !important;
}
.contactCity{
  display: grid;
  grid-template-columns: repeat(2, 50%);
  grid-template-rows: auto;
}
.contactCity>div{
  width: 100%;
  padding: 0 16px;
  text-align: left;
}
.contactCity p{
    color: #666;
    font-size: 12px;
    margin-top: -5px;
}
.description{
    font-size: 16px;
}
.grid{
  display: grid;
  grid-template-columns: repeat(2,50%);
  grid-template-rows: repeat(1, 100%);
}
.contactDetailsCard{
  display: flex;
}
.contactDetailsCard>:last-child{
  margin-left: 28px;
}
.timeAndDate{
  color: #666;
}

@media only screen and (max-width: 600px) {
  .gridContainer {
    display: grid;
    grid-gap: 0 5px;
    grid-template-columns: 100%;
    /* grid-template-rows: repeat( 50%); */
  }
  .grid{
    display: grid;
    grid-gap: 0 5px;
    grid-template-columns: 100%;
    grid-template-rows: repeat(2, 50%);
  }
  .UpdateContent{
    margin-top:-30px ;
  }
  .ContactCard>img{
    margin: 0;
    margin-top:36px;
  }
  .contactDetailsCard{
    /* display: block; */
    margin-bottom:8px ;
  }
  .contactDetailsCard>:last-child{
    margin-left: 28px;
  }
}