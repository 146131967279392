.pressRoomContainer {
    background-color: #fff;
    padding: 16px 16px;
}
.cardsContainer {
    max-width: 1140px;
    margin: 16px auto;
    width: 100%;
}
.newsCardsWrapper {
    display: grid;
    grid-template-columns: repeat(3, 33%);
    padding: 24px 0;
}

.newsCard {
    border: 1px solid #E1E1DF;
    border-radius:16px 0px 16px 16px;
    background: #FFFFFF;
    margin-right: 12px;
    padding: 16px;
    text-align:left;
}

.readMoreTag>a{
    text-decoration: none;
    color: #333;
    font-weight: 600;
}

@media only screen and (max-width:600px) {
    .newsCardsWrapper {
        display: grid;
        grid-template-columns: repeat(1, 100%);
        grid-template-rows: repeat(3, 33%);
        grid-gap:5px 0;
        padding: 24px 0;
    }
}