.headerContainer{
   display: flex; 
   justify-content: space-between;
   align-items: center;
   padding:16px;
   position: fixed;
   width: 100%;
   background-color: #FFF;
   z-index:5;
   box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
}
.navWrapper{
   width: 84%;
   margin: auto;
   display: flex;
   justify-content: space-between;
   align-items: center;
}
.logoWrapper{
   margin-left: 40px;
   height: 46px;
}
.anchorLink {
   font-size: 16px;
   font-weight: 600;
   text-decoration: none;
   color: #333333;
   margin-right: 30px;
}

@media only screen and (max-width:600px) {
   .logoWrapper{
      margin-left: 0;
   }
   .logoWrapper>img{
      height: 40px;
   }
   .navWrapper{
      width: 98%;
      margin: auto;
      display: flex;
      justify-content: space-between;
      align-items: center;
   }
}