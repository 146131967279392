.bannerCard {
    padding: 90px 4px 6px 4px;
    display: flex;
    justify-content: center;
    overflow: auto;
    align-items: center;
    background-color: #333333;
    color: #FFF;
}
.newsHeaders {
    font-size: 16px;
    margin:12px;
}
.knowMore{
    color:#FF6300;
    text-decoration: none;
    font-weight: 600;
    margin: 0px 8px;
}
.dot {
    color: #FFF;
    font-weight: 500;
    font-size:32px;
    margin: -14px 8px 0px 8px;
}