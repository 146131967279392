.footerBanner {
    background: #F5F5F5;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: center;
}
.footerBanner>div {
    padding: 24px 5px;

}
.copyRightsSection{
    color: #666;
    font-size: 14px;

}
.divider {
    border-left: 2px solid #C4C4C4 !important;
    margin: 0px 8px;
}
.TCSection>a{
    text-decoration: none;
    color: #333;
    font-size: 14px;
    font-weight: 600;
}

@media only screen and (max-width:600px){
    .footerBanner>div {
        padding: 12px;
    }
}