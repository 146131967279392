.container {
  background-color: #f5f5f5;
  padding: 0px 0;
}
.innerCard {
  max-width: 1140px;
  margin: 0 auto;
  padding: 16px 24px;
}
.scrollCard {
  background-color: #fff;
  width: 100% !important;
  max-height: 500px;
  overflow-y: scroll;
}
.formFields.MuiGrid-item{
  margin-bottom: 22px !important;
}
.MuiGrid-root .MuiGrid-item {
  padding-top: 0 !important;
}
.scrollCard::-webkit-scrollbar {
  width: 6px;
  background-color: #fff;
  border-radius: 8px;
}

.scrollCard::-webkit-scrollbar-thumb {
  background-color: #81959f;
  border-radius: 8px;
}
.socialMediaHeader {
  padding: 0 16px;
}
.socialMedia{
  margin-top:16px !important;
}
.postCard {
  padding: 16px;
}

.subTitle {
  font-size: 32px;
  font-weight: 600;
}
.userName {
  font-size: 16px;
  font-weight: 600;
  margin-left: 12px;
  color: #333;
}
.userId {
  font-size: 12px;
  color: #666;
  margin-left: 12px;
  margin-top: 2px;
}
.imageSection {
  width: 40px;
  height: 40px;
}
.postHeader {
  display: flex;
  justify-content: space-between;
}
.socialIcon {
  margin-right: 12px;
}
.userProfile {
  display: flex;
}
.content {
  color: #333;
}
.postInfoSection {
  display: flex;
  justify-content: space-between;
  padding: 24px 16px;
}
.postInfo {
  display: flex;
  align-items: center;
}
.postInfo > div {
  display: flex;
  align-items: center;
  margin-right: 20px;
}
.postInfo > div > img {
  margin-right: 3px;
}
.socialShares {
  display: flex;
  align-items: center;
}
.socialShares > img {
  margin-right: 3px;
}
.divider {
  margin-bottom: 16px !important;
  margin-top: 8px !important;
}
.contactInfo .MuiGrid-item {
  padding-bottom: 0 !important;
} 
.contactInfo > p {
  color: #333;
  font-size: 16px;
  margin-top: -15px;
}
body .textFields {
  border-radius: 8px 0px 8px 8px ;
  background-color: #ffff ;
  border: 1px solid #ccc  ;
  font-size: 16px ;
  font-weight: 400;
  color: #333;
  margin-right: 12px ;
  width: 98% ;
  padding: 7px !important;
  padding-right: 0 !important;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.text-right {
  text-align: right;
}
.remarksErrorContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: -10px 0;
  padding: 0 6px;
}
.remarkErrorText {
  color: #da5b5c;
  font-weight: 500;
  font-size: 12px;
  margin: 4px !important;
}
.loaderContainer {
  border-radius: 16px 0px 16px 16px;
}
.header {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}
.loaderWrapper {
  text-align: center;
}
.loaderContainer > div > img {
  width: 150px;
  height: 150px;
}

@media only screen and (max-width: 600px) {
  .scrollCard {
    max-height: 400px;
  }
  .textFields {
    width: 96% !important;
  }
  .emptyGrids {
    display: none !important;
  }
  .checkBoxWrapper {
    margin:8px 0;
  }
  .MuiGrid-root .MuiGrid-item {
    padding-top:4px !important;
  }
  
}
